<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-cloud-upload
        </v-icon> Upload Content
      </h2>
      <v-form v-model="isFormValid">
        <v-card-text class="text-body-1 text-center">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="categoryType"
                  :items="typeItems"
                  :rules="[(v) => !!v || 'Please select your content type.']"
                  label="Select type*"
                  outlined
                />
              </v-col>
              <v-col
                v-if="showMediaFolders"
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="folderId"
                  :items="mediaFolders"
                  label="Select Folder"
                  prepend-icon="mdi-folder"
                  menu-props="auto"
                  outlined
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row>
            <v-col
              cols="12"
            >
              <div
                class="outer-div"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <input
                  id="assetsFieldHandle"
                  ref="file"
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  class="w-px h-px input-file-style"
                  accept=".mp4,.gif,.jpg,.jpeg,.png"
                  @change="onChange"
                >

                <label
                  for="assetsFieldHandle"
                  class="block cursor-pointer"
                >
                  <div class="text-div">
                    Drag and drop files here
                    <v-spacer />
                    <v-icon
                      size="40"
                      color="grey"
                    >
                      mdi-cloud-upload
                    </v-icon>
                  </div>
                </label>
                <v-chip
                  v-for="(file, index) in bannerFile"
                  :key="index"
                  class="ma-2"
                  dark
                  color="#E0E0E0"
                  text-color="black"
                  close
                  @click:close="remove(index)"
                >
                  {{ file.name }}
                </v-chip>
              </div>
            </v-col>
            <br>
            <br>
            <v-row>
              <v-col
                cols="12"
              >
                <v-combobox
                  v-model="tags"
                  multiple
                  label="Add keywords"
                  hint="Type and then press 'Enter'"
                  prepend-icon="mdi-tag-multiple"
                  chips
                  deletable-chips
                  @paste="updateTags"
                />
              </v-col>
            </v-row>
          </v-container>
          <br>
          <p class="text-sm-left">
            *Indicates required field
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="cancel()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="!isFormValid"
            @click="uploadContentFile()"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
      :loading-value="loadingValue"
      :message="loadingMessage"
    />
  </v-dialog>
</template>
<script>
  import Errors from 'src/errors';
  import spinner from 'src/views/dashboard/component/SpinnerWithValue';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'ContentsUpload',
    components: {
      'centre-spinner': spinner,
    },
    mixins: [global],
    data: () => ({
      bannerFile: [],
      isFormValid: false,
      loadingMessage: '',
      loadingValue: 0,
      tags: [],
      categoryType: '',
      typeItems: ['Media File', 'Template'],
      loading: false,
      showModal: true,
      serverErrors: new Errors(),
      folderId: '',
      helper: new ModuleHelper(),
    }),
    computed: {
      getCategoryType () {
        if (this.categoryType === 'Media File') {
          return 'saved_content';
        }
        return 'template';
      },
      mediaFolders () {
        return this.$store.getters['mediafile/getMediaFolders'];
      },
      showMediaFolders () {
        if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_MEDIA_FOLDER) && this.permissionCheck('read-folder-list')) {
          return true;
        }
        return false;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.permissionCheck('read-folder-list') && await this.fetchMediaFolderList();
    },
    methods: {
      async fetchMediaFolderList () {
        await this.$store.dispatch('mediafile/fetchMediaFolders');
      },
      cancel () {
        this.$emit('closed');
      },
      async uploadContentFile () {
        this.loading = true;
        this.loadingMessage = 'Uploading file...';
        for (var i = 0; i < this.bannerFile.length; i++) {
          const formData = new FormData();
          formData.append('content_file', this.bannerFile[i]);
          formData.append('category_type', this.getCategoryType);
          formData.append('folder_id', this.folderId);
          for (var j = 0; j < this.tags.length; j++) {
            formData.append('tags[]', this.tags[j]);
          }
          var config = {
            onUploadProgress: function (progressEvent) {
              this.loadingValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
          await this.$store.dispatch('contents/uploadContent', formData, config);
        }
        this.$emit('success', this.getCategoryType);
        this.loading = false;
      },
      clearFile () {
        this.bannerFile = [];
        this.serverErrors.clear('content_file');
      },
      updateTags () {
        this.$nextTick(() => {
          this.select.push(...this.search.split(','));
          this.$nextTick(() => {
            this.search = '';
          });
        });
      },
      onChange () {
        const fi = document.getElementById('assetsFieldHandle');
        if (fi.files.length > 0) {
            for (var i = 0; i < fi.files.length; i++) {
                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                if (file >= 30096) {
                  const name = fi.files.item(i).name;
                  this.$store.dispatch('alert/onAlert', {
                    message: `File ${name} is too Big, Please select a file of less than 30 Mb.`,
                    type: Constants.ALERT_TYPE_ERROR,
                  }, { root: true });
                } else {
                  this.bannerFile.push(fi.files[i]);
                }
            }
        }
      },
      remove (i) {
        this.bannerFile.splice(i, 1);
      },
      dragover (event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.add('bg-green-300');
        }
      },
      dragleave (event) {
        // Clean up
        event.currentTarget.classList.remove('bg-green-300');
      },
      drop (event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.remove('bg-green-300');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
<style lang="css" scoped>
[v-cloak] {
  display: none !important;
}
.bg-green-300 {
  background-color: #66BB6A;
}
.input-file-style {
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.outer-div {
  height: 120px;
  color: #000;
  border: 3px solid #BDBDBD;
  border-style: dashed;
  border-radius: 20 px;
  border-collapse: separate;
}
.text-div {
  margin-top: 5px;
  text-align: center;
}
</style>
