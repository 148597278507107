<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <banner-editor
      v-if="showEditor"
      :selected-link="selectedLink"
      :selected-content="selectedContent"
      @closed="showEditor = false"
      @success="onEditSuccess"
      @errorthrown="onEditFailed"
    />
    <contents-upload
      v-if="showUpload"
      @closed="showUpload = false"
      @success="onUploadSuccess"
    />
    <image-preview
      v-if="showPreview"
      :url="selectedLink"
      @closed="showPreview = false"
    />
    <video-preview
      v-if="showVideoPreview"
      :url="selectedVideoLink"
      @closed="showVideoPreview = false"
    />
    <create-new-media-folder
      v-model="showCreateForm"
    />
    <edit-media-folder-details
      v-model="showEditForm"
    />
    <poster-my-wall-oauth-step-one
      v-if="showPosterMyWallAuthenticationForm"
      @closed="showPosterMyWallAuthenticationForm = false"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-image-multiple
      </v-icon> Media Library
    </h2>
    <br>
    <v-alert
      v-if="!permissionCheck('read-content')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-col
        cols="12"
        lg="12"
        class="text-right"
      >
        <v-btn
          v-if="showPosterMyWallEditor"
          color="#1565C0"
          @click="openPosterMyWallAuthenticationForm()"
        >
          PosterMyWall
        </v-btn>
        <v-btn
          v-if="showContentEditor"
          color="#455A64"
          @click="bannerEditor()"
        >
          Content Editor
        </v-btn>
        <v-btn
          color="#FF3700"
          @click="showUploadModal()"
        >
          Upload Content
        </v-btn>
      </v-col>
      <hr>
      <v-row>
        <v-col
          v-if="showMediaFolders"
          cols="12"
          md="3"
        >
          <v-navigation-drawer
            permanent
            color="#CFD8DC"
            light
          >
            <v-list>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-tooltip
                  v-if="permissionCheck('create-folder')"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      v-bind="attrs"
                      dark
                      color="#37474F"
                      small
                      v-on="on"
                      @click="showCreateForm = true"
                    >
                      <v-icon
                        small
                        dark
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Create New</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permissionCheck('read-folder-list') && permissionCheck('read-content')"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      v-bind="attrs"
                      fab
                      dark
                      color="#FF3700"
                      small
                      v-on="on"
                      @click="getAllUploadedContents()"
                    >
                      <v-icon
                        small
                        dark
                      >
                        mdi-file-multiple-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>ALL Files</span>
                </v-tooltip>
              </v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="nav-title-style">
                    <v-icon
                      left
                      color="#37474F"
                    >
                      mdi-folder-multiple
                    </v-icon>
                    Media Folders
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list
              nav
              dense
              light
            >
              <v-alert
                v-if="!permissionCheck('read-folder-list') && permissionCheck('read-content')"
                text
                prominent
                type="warning"
                color="red"
                border="left"
                class="authorization-alert"
              >
                You are not authorized to perform this action. Please contact your administrator.
              </v-alert>
              <v-list-item-group
                v-if="permissionCheck('read-folder-list') && permissionCheck('read-content')"
                v-model="selectedItem"
                color="#37474F"
              >
                <v-list-item
                  v-for="(item, index) in mediaFolders"
                  :key="index"
                  link
                  @click="getFolderContents(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon v-if="item.icon !== null">
                      {{ item.icon }}
                    </v-icon>
                    <v-icon v-else>
                      mdi-chevron-right-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                        v-bind="attrs"
                        style="font-size:14px;color:#37474F;"
                        v-on="on"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </template>
                    <span v-if="item.description !== null">{{ item.description }}</span>
                    <span v-else>N/A</span>
                  </v-tooltip>
                  <v-list-item-icon>
                    <v-icon
                      v-if="item.global === true"
                      size="20"
                    >
                      mdi-folder-account
                    </v-icon>
                    <v-icon
                      v-else
                      size="20"
                    >
                      mdi-folder
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon>
                    <v-icon
                      v-if="permissionCheck('edit-folder')"
                      size="20"
                      @click="editFolderName(item.id)"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col
          cols="12"
          :md="isMedisFolderAvailable"
        >
          <v-tabs
            color="#FF3700"
            background-color="#CFD8DC"
          >
            <v-tab>
              Templates
            </v-tab>
            <v-tab>Media Files</v-tab>
            <v-tab-item class="my-tabs-background-style">
              <br>
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="no-file-alert">
                    <h4 v-if="showNoTemplateAlert">
                      No template files found. Please upload contents to make use of it.
                    </h4>
                  </div>
                </v-col>
                <v-col
                  v-for="(template, n) in getTemplateContents"
                  :key="n"
                  class="d-flex child-flex"
                  :cols="columnToDisplay"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="{ 'on-hover': hover }"
                      color="#CFD8DC"
                    >
                      <div v-if="isContentImgOrGif(template.content_type)">
                        <v-img
                          :src="getFilePath(template.file_path)"
                          :lazy-src="getFilePath(template.file_path)"
                          aspect-ratio="1"
                          width="100%"
                          height="180"
                          class="my-imag-style"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              />
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                      <div v-else>
                        <video
                          width="100%"
                          height="180"
                          controls
                        >
                          <source
                            :src="getFilePath(template.file_path)"
                            :lazy-src="getFilePath(template.file_path)"
                            type="video/mp4"
                          >
                        </video>
                      </div>
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="#036358"
                        >
                          <v-col v-if="isContentImgOrGif(template.content_type)">
                            <v-icon
                              large
                              @click="showImg(template.file_path)"
                            >
                              mdi-image
                            </v-icon>
                          </v-col>
                          <v-col v-if="!isContentImgOrGif(template.content_type)">
                            <v-icon
                              large
                              @click="showVideo(template.file_path)"
                            >
                              mdi-video
                            </v-icon>
                          </v-col>
                          <v-col v-if="isContentImg(template.content_type)">
                            <v-icon
                              large
                              @click="editImg(template, template.file_path)"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-col>
                          <v-col>
                            <v-icon
                              v-if="permissionCheck('delete-content')"
                              large
                              @click="deleteImage(template.unique_file_name, n, 'template')"
                            >
                              mdi-trash-can
                            </v-icon>
                          </v-col>
                        </v-overlay>
                      </v-fade-transition>
                      <br>
                      <div class="file-details">
                        <p class="my-p-style">
                          File Name:-
                          <font
                            class="font-my-style"
                          >
                            {{ template.original_file_name }}
                          </font>
                        </p>
                        <p class="my-p-style">
                          File Dimension:-
                          <font
                            class="font-my-style"
                          >
                            {{ template.dimension }}
                          </font>
                        </p>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item class="my-tabs-background-style">
              <br>
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="no-file-alert">
                    <h4 v-if="showNoMediaFilesAlert">
                      No media files found. Please upload contents to make use of it.
                    </h4>
                  </div>
                </v-col>
                <v-col
                  v-for="(banner, n) in savedContents"
                  :key="n"
                  class="d-flex child-flex"
                  :cols="columnToDisplay"
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="{ 'on-hover': hover }"
                      color="#CFD8DC"
                    >
                      <div v-if="isContentImgOrGif(banner.content_type)">
                        <v-img
                          :src="getFilePath(banner.file_path)"
                          :lazy-src="getFilePath(banner.file_path)"
                          aspect-ratio="1"
                          width="100%"
                          height="180"
                          class="my-imag-style"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              />
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                      <div v-else>
                        <video
                          width="100%"
                          height="180"
                          controls
                        >
                          <source
                            :src="getFilePath(banner.file_path)"
                            :lazy-src="getFilePath(banner.file_path)"
                            type="video/mp4"
                          >
                        </video>
                      </div>
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="#036358"
                        >
                          <v-col v-if="isContentImgOrGif(banner.content_type)">
                            <v-icon
                              large
                              @click="showImg(banner.file_path)"
                            >
                              mdi-image
                            </v-icon>
                          </v-col>
                          <v-col v-if="!isContentImgOrGif(banner.content_type)">
                            <v-icon
                              large
                              @click="showVideo(banner.file_path)"
                            >
                              mdi-video
                            </v-icon>
                          </v-col>
                          <v-col v-if="isContentImg(banner.content_type)">
                            <v-icon
                              large
                              @click="editImg(banner, banner.file_path)"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-col>
                          <v-col>
                            <v-icon
                              v-if="permissionCheck('delete-content')"
                              large
                              @click="deleteImage(banner.unique_file_name,'banner')"
                            >
                              mdi-trash-can
                            </v-icon>
                          </v-col>
                        </v-overlay>
                      </v-fade-transition>
                      <br>
                      <div class="file-details">
                        <p class="my-p-style">
                          File Name:-
                          <font
                            class="font-my-style"
                          >
                            {{ banner.original_file_name }}
                          </font>
                        </p>
                        <p class="my-p-style">
                          File Dimension:-
                          <font
                            class="font-my-style"
                          >
                            {{ banner.dimension }}
                          </font>
                        </p>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="alert"
        :color="alertColor"
        icon="mdi-check-circle"
        transition="scale-transition"
        top
        right
      >
        {{ alertMessage }}
      </v-snackbar>
      <delete-dialog
        v-if="showDelete"
        :title="'Delete Image?'"
        @closed="showDelete = false"
        @confirmed="deleteConfirmed()"
      />
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>

  import axios from 'src/app-axios';
  import BannerEditor from './BannerEditor';
  import ContentsUpload from './ContentsUpload';
  import { cdnUrl, storageUrl } from 'src/app-globals';
  import Errors from 'src/errors';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import ImagePreview from 'src/views/dashboard/component/ImagePreview';
  import VideoPreview from 'src/views/dashboard/component/VideoPreview';
  import Constants from 'src/constants';
  import CreateNewMediaFolder from '../MediaLibrary/CreateNewMediaFolder.vue';
  import EditMediaFolderDetails from '../MediaLibrary/EditMediaFolderDetails.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import PosterMyWallOauthStepOne from '../PosterMyWall/PosterMyWallOauthStepOne.vue';

  export default {
    name: 'BannerGallery',
    components: {
      'banner-editor': BannerEditor,
      'contents-upload': ContentsUpload,
      'delete-dialog': DeleteDialog,
      'image-preview': ImagePreview,
      'video-preview': VideoPreview,
      'create-new-media-folder': CreateNewMediaFolder,
      'edit-media-folder-details': EditMediaFolderDetails,
      'centre-spinner': spinner,
      'poster-my-wall-oauth-step-one': PosterMyWallOauthStepOne,
    },
    mixins: [global],
    data: () => ({
      showEditor: false,
      showUpload: false,
      showPreview: false,
      selectedLink: null,
      alertMessage: '',
      alert: false,
      alertColor: 'green',
      serverErrors: new Errors(),
      showDelete: false,
      deleteContent: {
        unqFileName: '',
        type: '',
      },
      showVideoPreview: false,
      selectedVideoLink: null,
      selectedContent: {},
      showCreateForm: false,
      showEditForm: false,
      helper: new ModuleHelper(),
      loading: false,
      selectedItem: -1,
      showPosterMyWallAuthenticationForm: false,
    }),
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      savedContents () {
        return this.$store.getters['contents/getSavedContents'];
      },
      getTemplateContents () {
        return this.$store.getters['contents/getTemplateContents'];
      },
      mediaFolders () {
        return this.$store.getters['mediafile/getMediaFolders'];
      },
      showMediaFolders () {
        return this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_MEDIA_FOLDER);
      },
      showNoTemplateAlert () {
        if (this.getTemplateContents.length === 0) {
          return true;
        }
        return false;
      },
      showNoMediaFilesAlert () {
        if (this.savedContents.length === 0) {
          return true;
        }
        return false;
      },
      showPosterMyWallEditor () {
        if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_TEMPLATE_EDITOR)) {
          return true;
        }
        return false;
      },
      showContentEditor () {
         if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_IMAGE_EDITOR)) {
          return true;
        }
        return false;
      },
      columnToDisplay () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 3;
        } else {
          return 6;
        }
      },
      isMedisFolderAvailable () {
        if (this.showMediaFolders) {
          return 9;
        } else {
          return 12;
        }
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    created () {
      this.permissionCheck('read-content') && this.getAllUploadedContents();
    },
    async mounted () {
      (this.permissionCheck('read-folder-list') && this.permissionCheck('read-content')) && await this.fetchMediaFolders();
    },
    methods: {
      async getAllUploadedContents () {
        this.loading = true;
        this.selectedItem = -1;
        this.$store.dispatch('contents/clearSavedContents');
        this.$store.dispatch('contents/clearTemplates');
        await this.$store.dispatch('contents/fetchTemplates', {
          params: {
            categoryType: 'template',
          },
        });
        await this.$store.dispatch('contents/fetchSavedContents', {
          params: {
            categoryType: 'saved_content',
          },
        });
        this.loading = false;
      },
      fetchMediaFolders () {
        this.$store.dispatch('mediafile/fetchMediaFolders');
      },
      bannerEditor () {
        if (this.permissionCheck('read-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showEditor = true;
      },
      deleteConfirmed () {
        this.showDelete = false;
        this.deleteApiCall(this.deleteContent.unqFileName);
      },
      deleteImage (unqFileName, type) {
        this.deleteContent.unqFileName = unqFileName;
        this.deleteContent.type = type;

        this.showDelete = true;
      },
      showUploadModal () {
        if (this.permissionCheck('upload-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showUpload = true;
      },
      showImg (link) {
        this.selectedLink = `${cdnUrl}/${link}`;
        this.showPreview = true;
      },
      showVideo (link) {
        this.selectedVideoLink = `${cdnUrl}/${link}`;
        this.showVideoPreview = true;
      },
      editImg (content, link) {
        this.selectedLink = `${storageUrl}/${link}`;
        this.selectedContent = content;
        this.bannerEditor();
      },
      onEditSuccess () {
        this.$store.dispatch('alert/onAlert', {
          message: 'Successfully saved the edited content.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.showEditor = false;
        this.$store.dispatch('contents/clearSavedContents');
        if (this.permissionCheck('read-content') === false) {
          return;
        }
        this.$store.dispatch('contents/fetchSavedContents', {
          params: {
            categoryType: 'saved_content',
          },
        });
      },
      onUploadSuccess (value) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Content saved successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.showUpload = false;
        if (this.permissionCheck('read-content') === false) {
          return;
        }
        if (value === 'saved_content') {
          this.$store.dispatch('contents/clearSavedContents');
          this.$store.dispatch('contents/fetchSavedContents', {
            params: {
              categoryType: 'saved_content',
            },
          });
        } else {
          this.$store.dispatch('contents/clearTemplates');
          this.$store.dispatch('contents/fetchTemplates', {
            params: {
              categoryType: 'template',
            },
          });
        }
      },
      onEditFailed () {
        this.$store.dispatch('alert/onAlert', {
          message: 'Failed to save the edited content, Please contact support',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        this.showEditor = false;
      },

      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },

      deleteApiCall (unqFileName) {
        const url = 'api/banners/content/' + unqFileName;
        axios.delete(url).then(
          response => {
            if (response.status === 204) {
              this.alertMessage = 'Deleted image from the records.';
              this.alertColor = 'green';
              this.alert = true;
              if (this.deleteContent.type === 'banner') {
                this.$store.dispatch('contents/onSavedContentRemove', unqFileName);
                return;
              }
              this.$store.dispatch('contents/onTemplateContentRemove', unqFileName);
            }
          }).catch(error => {
          this.alertMessage = error.response.data.error;
          this.alertColor = 'red';
          this.alert = true;
        });
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      isContentImg (contentType) {
        if (contentType === 'image') {
          return true;
        }
        return false;
      },
      async editFolderName (id) {
        await this.$store.dispatch('mediafile/fetchMediaFolderDetailsForEdit', {
          folderId: id,
        }).then(response => {
          this.showEditForm = true;
        });
      },
      async getFolderContents (folderId) {
        this.loading = true;
        await this.$store.dispatch('contents/fetchSelectedFolderTemplates', {
          params: {
            categoryType: 'template',
            folderId: folderId,
          },
        });
        await this.$store.dispatch('contents/fetchSelectedFolderSavedContents', {
          params: {
            categoryType: 'saved_content',
            folderId: folderId,
          },
        });
        this.loading = false;
      },
      openPosterMyWallAuthenticationForm () {
        if (this.permissionCheck('read-poster-my-wall-uri') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showPosterMyWallAuthenticationForm = true;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
  background-color: #CFD8DC !important;
}
.my-p-style {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.font-my-style {
  font-weight: bold;
  font-size: 13px;
  color: #37474F;
}
.file-details {
  text-align: left;
  margin-left: 10px;
}
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 18px;
}
.no-file-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-imag-style {
   background-color: #CFD8DC !important;
}
</style>
