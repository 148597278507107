<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="1600"
      max-height="1200"
    >
      <centre-spinner
        :loading="showProgress"
      />

      <tui-image-editor
        v-if="showEditor"
        ref="tuiImageEditor"
        :options="options"
        @addText="onAddText"
      >
        <v-btn>
          Save
        </v-btn>
      </tui-image-editor>
      <spinner-with-value
        :loading="loading"
        :loading-value="loadingValue"
        :message="loadingMessage"
      />
    </v-dialog>
    <v-dialog
      v-if="showMediaFolders"
      v-model="folderDialog"
      max-width="600px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-folder-search-outline
          </v-icon> Select Folder
        </h2>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-checkbox
                    v-model="allFiles"
                    label="All Files"
                    color="#FF3700"
                    readonly
                    light
                    class="my-checkbox"
                  />
                </v-col>
              </v-row>
              <p
                class="my-p-style"
              >
                <font>
                  Please select a folder to which you want to save the edited content
                </font>
              </p>
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-autocomplete
                    v-model="folderId"
                    :items="mediaFolders"
                    label="Select Folder"
                    menu-props="auto"
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="folderDialog = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="saveEditedContent()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import 'tui-image-editor/dist/tui-image-editor.css';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import { ImageEditor } from '@toast-ui/vue-image-editor';
  import SpinnerWithValue from 'src/views/dashboard/component/SpinnerWithValue';
  import 'tui-color-picker/dist/tui-color-picker.css';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';
  import global from 'src/mixins/global';

  export default {
    components: {
      'tui-image-editor': ImageEditor,
      'centre-spinner': spinner,
      'spinner-with-value': SpinnerWithValue,
    },
    mixins: [global],
    props: {
      selectedLink: {
        default: null,
        type: String,
        required: false,
      },
      selectedContent: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        showProgress: true,
        showEditor: false,
        useDefaultUI: false,
        loading: false,
        loadingValue: 0,
        loadingMessage: '',
        options: { // for tui-image-editor component's "options" prop
          cssMaxWidth: 1200,
          cssMaxHeight: 1000,

          usageStatistics: false,

          selectionStyle: {
            cornerSize: 50,
            rotatingPointOffset: 70,
          },

          includeUI: {
            initMenu: 'filter',
            menuBarPosition: 'bottom',
            uiSize: {
              width: '100%',
              height: '1000px',
            },
            theme: this.getWhiteTheme(),
          },
          resizeInfo: {
            uiSize: {
              width: '500px',
              height: '500px',
            },
            imageSize: {
              oldWidth: 100,
              oldHeight: 100,
              newWidth: 700,
              newHeight: 700,
            },
          },
        },
        helper: new ModuleHelper(),
        folderDialog: false,
        folderId: '',
        allFiles: true,
      };
    },
    computed: {
      mediaFolders () {
        return this.$store.getters['mediafile/getMediaFolders'];
      },
      showMediaFolders () {
       if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_MEDIA_FOLDER) && this.permissionCheck('read-folder-list')) {
        return true;
       }
       return false;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
   async  mounted () {
      this.checkAndSetImageLink();
      setTimeout(() => {
        this.showProgress = false;
      }, 2000);
      this.showEditor = true;

      setTimeout(() => { // As tui-vue-toaster puts some delay on building editor, we need this delay here.
        this.EditorHtmlChanges();
      }, 1000);
      (this.permissionCheck('read-folder-list') && this.permissionCheck('read-content')) && await this.fetchMediaFolders();
    },
    methods: {
      fetchMediaFolders () {
        this.$store.dispatch('mediafile/fetchMediaFolders');
      },
      checkAndSetImageLink () {
        if (this.selectedLink !== null) {
          const loadImage = {
            path: this.selectedLink,
            name: 'sample_name',
          };
          Object.assign(this.options.includeUI, { loadImage: loadImage });
        }
      },
      EditorHtmlChanges () {
        const headerLogo = document.getElementsByClassName('tui-image-editor-header');
        // Remove the boring header logo
        headerLogo[0].firstElementChild.remove();

        // Add save button to editor
        const saveButton = '<button class="tui-image-editor-download-btn" id="tui-save-btn">Save</button>';
        const closeButton = '<button class="tui-image-editor-dowload-btn" id="tui-close-btn">Close</button>';
        headerLogo[0].firstElementChild.insertAdjacentHTML('afterbegin', saveButton);
        headerLogo[0].firstElementChild.insertAdjacentHTML('afterbegin', closeButton);

        // Add listener to save button
        document.getElementById('tui-save-btn').addEventListener('click', () => {
          this.saveChangesToBackEnd();
        });
        // Add listener to close button
        document.getElementById('tui-close-btn').style.background = '#2196F3';
        document.getElementById('tui-close-btn').style.color = '#FFF';
        document.getElementById('tui-close-btn').addEventListener('click', () => {
          this.showModal = false;
        });
      },

      onAddText (props) {
        setTimeout(() => { // As tui-vue-toaster puts some delay on building editor, we need this delay here.
          this.textParamChanges(props);
        }, 1000);
      },

      textParamChanges (props) {
        const editor = this.$refs.tuiImageEditor;
        this.$refs.tuiImageEditor.invoke('changeTextStyle', editor.editorInstance.activeObjectId, {
          fontFamily: 'Calibri',
        });
      },

      saveChangesToBackEnd () {
        if (this.showMediaFolders === true) {
          this.folderDialog = true;
        } else {
          this.saveEditedContent();
        }
      },
      async saveEditedContent () {
          if (this.permissionCheck('upload-content') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action as you require "upload-content" permission. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          this.showModal = false;
          return;
        }
        this.folderDialog = false;
        this.loading = true;
        this.loadingMessage = 'Saving the changes..';
        const file = this.convertUrlToFile(this.$refs.tuiImageEditor.invoke('toDataURL'), this.selectedContent.original_file_name);
        const formData = new FormData();
        formData.append('content_file', file);
        formData.append('category_type', 'saved_content');
        formData.append('folder_id', this.folderId);
        var config = {
          onUploadProgress: function (progressEvent) {
            this.loadingValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        await this.$store.dispatch('contents/uploadContent', formData, config)
          .then(response => {
            this.$emit('success');
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$emit('errorthrown');
          });
      },

      convertUrlToFile (dataUrl, fileName) {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
      },

      getWhiteTheme () {
        return {
          'common.backgroundColor': '#fff',
          'common.border': '1px solid #c1c1c1',
        };
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
::v-deep .my-checkbox .v-label {
 font-size: 15px;
 color: #37474F;
 font-weight: bold;
}
</style>
