<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-lock-open
        </v-icon> Authenticate
      </h2>
      <br>
      <v-form>
        <v-card-text class="text-body-1 text-center">
          <v-container fluid>
            <p
              class="my-p-style"
            >
              Welcome to <font class="font-style">
                PosterMyWall
              </font>
              Editor
            </p>
            <p
              class="p-style"
            >
              An Advanced Editor For Your Content Management<v-icon color="#2E7D32">
                mdi-checkbox-marked-circle
              </v-icon>
            </p>
            <br>
            <v-container v-if="showAuthProgress">
              <v-row
                class="fill-height"
                align-content="center"
                justify="center"
              >
                <v-col
                  class="activation-alert"
                  cols="12"
                >
                  {{ authenticationProcessAlert }}
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="#FF3700"
                    indeterminate
                    rounded
                    height="10"
                  />
                </v-col>
              </v-row>
            </v-container>
            <p
              v-if="!showAuthProgress"
              class="alert-style"
            >
              ( NB:- Please do buy a subscription package from official <a
                class="link-style"
                href="https://www.postermywall.com/"
                target="_blank"
              >
                PosterMyWall
              </a> page and use those credentials in accessing this feature.)
            </p>
          </v-container>
        </v-card-text>
        <br>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="btnDisable"
            @click="authorize()"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'PosterMyWallOauthStepOne',
    components: {
      'centre-spinner': spinner,
    },

    data: () => ({
      showModal: true,
      loading: false,
      showAuthProgress: false,
      authenticationProcessAlert: '',
      btnText: 'Authorize',
      btnDisable: false,
    }),
    computed: {
      PosterMyWallAuthenticationDetails () {
        return this.$store.getters['contents/getPosterMyWallAuthenticationDetails'];
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('contents/fetchPosterMyWallAuthenticationDetails');
      this.loading = false;
    },
    methods: {
      authorize () {
        this.btnDisable = true;
        this.btnText = 'Authorizing...';
        this.showAuthProgress = true;
        this.authenticationProcessAlert = 'Checking...';
        setTimeout(() => {
          this.hitPosterMyWallOauthUri();
        }, 2000);
      },
      hitPosterMyWallOauthUri () {
        if (this.PosterMyWallAuthenticationDetails.pmw_user_id === null) {
         this.authenticationProcessAlert = 'Redirecting...';
         setTimeout(() => {
          window.location = this.PosterMyWallAuthenticationDetails.auth_uri;
         }, 1000);
        } else {
          this.authenticationProcessAlert = 'Template Editor Loading...';
          setTimeout(() => {
            this.$router.push({ name: 'PosterMyWall Editor Landing Page' });
          }, 2000);
        }
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 20px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.font-style {
  color: #2196F3;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.p-style {
  font-size: 16px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.alert-style {
  font-size: 14px;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.link-style {
  color: #FF3700;
  font-weight: bold;
}
.activation-alert {
  font-size: 20px;
  color: #37474F;
  font-weight: bold;
  text-align: center;
}
</style>
