<template>
  <section>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="title-style">
          <v-icon class="icon-style">
            mdi-folder-plus
          </v-icon> Create Folder
        </h2>
        <v-card-text>
          <v-form
            ref="form"
            v-model="isFormValid"
          >
            <v-container>
              <v-row class="text-center">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="folderName"
                    label="Folder Name*"
                    hint="Name for your reference - 'Landscape' "
                    :rules="[(v) => !!v || 'This field is required']"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-combobox
                    v-model="selectedIcon"
                    :items="icons"
                    label="Select Icon"
                    item-text="name"
                    item-value="icon"
                    menu-props="auto"
                    prepend-icon="mdi-select"
                  >
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <v-icon left>
                        {{ data.item.icon }}
                      </v-icon> <span class="cb-item">{{ data.item.name }}</span>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <br>
              <v-textarea
                v-model="folderDescription"
                filled
                label="Folder Description"
              />
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-switch
                    v-model="shared"
                    label="Shared Folder"
                    hint="Enabling will display the folder across all users"
                    :persistent-hint="true"
                    color="#FF3700"
                  />
                </v-col>
              </v-row>
            </v-container>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="close()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="create()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import Constants from 'src/constants';

export default {
    name: 'CreateNewFolder',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data () {
        return {
          isFormValid: false,
          folderName: '',
          selectedIcon: null,
          icons: [
            { name: 'Image', icon: 'mdi-image-outline' },
            { name: 'Image Folder', icon: 'mdi-image-multiple-outline' },
            { name: 'Folder', icon: 'mdi-folder-multiple-outline' },
            { name: 'Landscape', icon: 'mdi-crop-landscape' },
            { name: 'Portrait', icon: 'mdi-crop-portrait' },
            { name: 'Video Folder', icon: 'mdi-file-video-outline' },
            { name: 'Right Drop Circle', icon: 'mdi-arrow-right-drop-circle-outline' },
            { name: 'Right Circle', icon: 'mdi-chevron-right-circle-outline' },
          ],
          folderDescription: '',
          shared: false,
        };
    },
    computed: {
      dialog: {
        get: function () {
          return this.value;
        },
        set: function (value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      async create () {
        if (this.$refs.form.validate()) {
          await this.$store.dispatch('mediafile/createNewMediaFolder', {
            name: this.folderName,
            description: this.folderDescription,
            global: this.shared,
            icon: this.selectedIcon.icon,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Folder created successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.close();
            this.$store.dispatch('mediafile/fetchMediaFolders');
          });
        };
      },
      close () {
        this.dialog = false;
        this.isFormValid = false;
        this.folderName = '';
        this.selectedIcon = null;
        this.folderDescription = '';
        this.shared = false;
      },
    },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
